import type { PermissionName } from '@jgp-er-dev/permissions'
import React, { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { Path } from '../../utils/constants'
import PageLink from './PageLink'

interface Props {
  checkPermissions: (permissions: PermissionName[]) => boolean
  location: ReturnType<typeof useLocation>
}

const NavigationLinks: React.FC<Props> = (props) => {
  const { checkPermissions } = props

  const shouldShowStatistics = useMemo(
    () => checkPermissions(['v2:esg_reports:score_statistics']),
    [checkPermissions]
  )

  const shouldShowPersonalizedReports = useMemo(
    () => checkPermissions(['v2:esg_reports:personalized_reports']),
    [checkPermissions]
  )

  const shouldShowThemes = useMemo(
    () => checkPermissions(['v2:esg_reports:themes']),
    [checkPermissions]
  )

  return (
    <div className="hidden md:flex ml-16 h-full">
      <PageLink
        label="Home"
        href={Path.REPORTS}
        active={props.location.pathname === Path.REPORTS}
      />
      <PageLink
        label="Empresas"
        href={Path.REPORTS_COMPANY}
        active={props.location.pathname.startsWith(Path.REPORTS_COMPANY)}
      />
      <PageLink
        label="Setores"
        href={Path.REPORTS_SECTORAL}
        active={props.location.pathname.startsWith(Path.REPORTS_SECTORAL)}
      />
      {shouldShowStatistics && (
        <PageLink
          label="Estatísticas"
          href={Path.REPORTS_STATISTICS}
          active={props.location.pathname.startsWith(Path.REPORTS_STATISTICS)}
        />
      )}
      {shouldShowPersonalizedReports && (
        <PageLink
          label="Personalizar"
          href={Path.REPORTS_PERSONALIZED}
          active={props.location.pathname.startsWith(Path.REPORTS_PERSONALIZED)}
        />
      )}
      {shouldShowThemes && (
        <PageLink
          label="Temas"
          href={Path.REPORTS_THEMES}
          active={props.location.pathname.startsWith(Path.REPORTS_THEMES)}
        />
      )}
      <PageLink label="Ajuda" href="#" />
    </div>
  )
}

export default NavigationLinks

import classNames from 'classnames'
import React, { useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { useUser } from '../../contexts/User'
import DropdownMenu from './DropdownMenu'
import LogoSection from './LogoSection'
import MobileMenu from './MobileMenu'
import NavigationLink from './NavigationLink'
import UserMenu from './UserMenu'

interface Props {
  className?: string
}

const TopBar: React.FC<Props> = (props) => {
  const { email, name, checkPermissions } = useUser()
  const location = useLocation()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false)
  const userIconRef = useRef<HTMLDivElement>(null)

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
    if (isUserMenuOpen) setIsUserMenuOpen(false)
  }

  const toggleUserMenu = () => {
    setIsUserMenuOpen(!isUserMenuOpen)
    if (isMenuOpen) setIsMenuOpen(false)
  }

  const closeMenu = () => {
    setIsMenuOpen(false)
  }

  const closeDropdown = () => {
    setIsUserMenuOpen(false)
  }

  return (
    <div
      className={classNames(
        'z-10 flex w-full justify-center shadow-md',
        props.className
      )}
    >
      <div className="flex h-14 w-full max-w-screen-xl items-center justify-between px-4">
        <LogoSection toggleMenu={toggleMenu} />
        <NavigationLink
          checkPermissions={checkPermissions}
          location={location}
        />
        <UserMenu
          name={name}
          email={email}
          toggleUserMenu={toggleUserMenu}
          userIconRef={userIconRef}
        />
      </div>
      <MobileMenu
        isMenuOpen={isMenuOpen}
        location={location}
        checkPermissions={checkPermissions}
        closeMenu={closeMenu}
      />
      <DropdownMenu
        isUserMenuOpen={isUserMenuOpen}
        userIconRef={userIconRef}
        name={name}
        email={email}
        closeDropdown={closeDropdown}
      />
    </div>
  )
}

export default TopBar

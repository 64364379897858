import React from 'react'

import logo from '../../assets/logo.png'
import { Path } from '../../utils/constants'
import MenuIcon from '../icons/Menu'

interface Props {
  toggleMenu: () => void
}

const LogoSection: React.FC<Props> = (props) => {
  return (
    <span className="flex h-full flex-shrink-0 items-center">
      <button onClick={props.toggleMenu} className="md:hidden mr-4">
        <MenuIcon className="h-6 w-6 text-jgp-secondary-light" />
      </button>
      <a className="my-4" href={Path.REPORTS}>
        <img className="h-8" src={logo} alt="Logo" />
      </a>
    </span>
  )
}

export default LogoSection

import type { PermissionName } from '@jgp-er-dev/permissions'
import React from 'react'
import { useLocation } from 'react-router-dom'

import { Path } from '../../utils/constants'
import PageLink from './PageLink'

interface Props {
  isMenuOpen: boolean
  location: ReturnType<typeof useLocation>
  checkPermissions: (permissions: PermissionName[]) => boolean
  closeMenu: () => void
}

const MobileMenu: React.FC<Props> = (props) => {
  if (!props.isMenuOpen) return null

  const shouldShowStatistics = props.checkPermissions([
    'v2:esg_reports:score_statistics',
  ])
  const shouldShowPersonalizedReports = props.checkPermissions([
    'v2:esg_reports:personalized_reports',
  ])
  const shouldShowThemes = props.checkPermissions(['v2:esg_reports:themes'])

  return (
    <div className="md:hidden fixed top-14 left-0 w-full bg-white shadow-md z-50">
      <div className="px-4 py-2 space-y-4">
        <PageLink
          label="Home"
          href={Path.REPORTS}
          active={props.location.pathname === Path.REPORTS}
          onClick={props.closeMenu}
        />
        <PageLink
          label="Empresas"
          href={Path.REPORTS_COMPANY}
          active={props.location.pathname.startsWith(Path.REPORTS_COMPANY)}
          onClick={props.closeMenu}
        />
        <PageLink
          label="Setores"
          href={Path.REPORTS_SECTORAL}
          active={props.location.pathname.startsWith(Path.REPORTS_SECTORAL)}
          onClick={props.closeMenu}
        />
        {shouldShowStatistics && (
          <PageLink
            label="Estatísticas"
            href={Path.REPORTS_STATISTICS}
            active={props.location.pathname.startsWith(Path.REPORTS_STATISTICS)}
            onClick={props.closeMenu}
          />
        )}
        {shouldShowPersonalizedReports && (
          <PageLink
            label="Personalizar"
            href={Path.REPORTS_PERSONALIZED}
            active={props.location.pathname.startsWith(
              Path.REPORTS_PERSONALIZED
            )}
            onClick={props.closeMenu}
          />
        )}
        {shouldShowThemes && (
          <PageLink
            label="Temas"
            href={Path.REPORTS_THEMES}
            active={props.location.pathname.startsWith(Path.REPORTS_THEMES)}
            onClick={props.closeMenu}
          />
        )}
        <PageLink label="Ajuda" href="#" onClick={props.closeMenu} />{' '}
      </div>
    </div>
  )
}

export default MobileMenu

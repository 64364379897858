import React from 'react'
import { Link } from 'react-router-dom'

const LowerFooter: React.FC = () => (
  <div className="flex justify-center p-4 font-work-sans">
    <div className="flex w-full max-w-screen-xl flex-col justify-between md:flex-row md:items-center">
      <div className="flex w-full md:justify-center overflow-x-auto whitespace-nowrap text-sm md:w-auto py-3 md:py-0">
        <Link
          className="border-r border-black pr-4 md:pr-8 flex-shrink-0"
          to="#"
        >
          Termos & Condições
        </Link>
        <Link
          className="border-r border-black px-4 md:px-8 flex-shrink-0"
          to="#"
        >
          Política de Privacidade
        </Link>
        <Link className="px-4 md:px-8 flex-shrink-0" to="#">
          FAQ
        </Link>
      </div>
      <div className="mt-4 text-xs text-jgp-secondary-dark md:mt-0 md:text-right">
        Copyright © 2021 JGP · Todos os direitos reservados
      </div>
    </div>
  </div>
)

export default LowerFooter

import classNames from 'classnames'
import React, { useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import ESGPlatformHeader from '../../../../components/ESGPlatformHeader'
import Select from '../../../../components/Select'
import { useWatchLoading } from '../../../../contexts/Loading'
import { getCompanyUrl, getSectorUrl, slugify } from '../../../../utils'
import { Path } from '../../../../utils/constants'
import PaginatedList from './PaginatedList'

interface Props {
  loading: boolean
  companies: CompanyBase[]
  sectors: string[]
  favoriteCompanies: string[]
  favoriteSectors: string[]
  recentCompanies: string[]
  recentSectors: string[]
  className?: string
}

const genCompanyListFromSlugs = (companies: CompanyBase[], slugs: string[]) =>
  slugs
    .filter((slug) =>
      companies.some((company) => slugify(company.name) === slug)
    )
    .map((slug) => ({
      label: companies.find((company) => slugify(company.name) === slug)!.name,
      url: `${Path.REPORTS_COMPANY}/${slug}`,
    }))

const genSectorListFromSlugs = (sectors: string[], slugs: string[]) =>
  slugs
    .filter((slug) => sectors.some((sector) => slugify(sector) === slug))
    .map((slug) => ({
      label: sectors.find((sector) => slugify(sector) === slug)!,
      url: `${Path.REPORTS_SECTORAL}/${slug}`,
    }))

const DEFAULT_SUGGESTED_COMPANIES = [
  { label: "Rede D'or", url: "/reports/company/rede-d'or" },
  { label: 'SulAmérica', url: '/reports/company/sulamerica' },
  { label: 'Hapvida', url: '/reports/company/hapvida' },
]

const DEFAULT_SUGGESTED_SECTORS = [
  { label: 'Apparel', url: '/reports/sector/apparel' },
  { label: 'Financials', url: '/reports/sector/financials' },
  { label: 'Car Rental', url: '/reports/sector/car-rental' },
]

const MAX_SUGGESTIONS = 3

const Reports: React.FC<Props> = (props) => {
  const [selectedCompany, setSelectedCompany] = useState<SelectOption | null>(
    null
  )
  const [selectedSector, setSelectedSector] = useState<SelectOption | null>(
    null
  )

  const history = useHistory()
  useWatchLoading(props.loading)

  const companySelectRef = useRef<any>(null)
  const sectorSelectRef = useRef<any>(null)

  const companyOptions = useMemo(
    () =>
      props.companies
        .map((company) => ({
          label: company.name,
          value: slugify(company.name),
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [props.companies]
  )

  const favoriteCompanies = useMemo(
    () => genCompanyListFromSlugs(props.companies, props.favoriteCompanies),
    [props.companies, props.favoriteCompanies]
  )

  const favoriteSectors = useMemo(
    () => genSectorListFromSlugs(props.sectors, props.favoriteSectors),
    [props.sectors, props.favoriteSectors]
  )

  const recentCompanies = useMemo(
    () =>
      genCompanyListFromSlugs(props.companies, props.recentCompanies).reverse(),
    [props.companies, props.recentCompanies]
  )

  const recentSectors = useMemo(
    () => genSectorListFromSlugs(props.sectors, props.recentSectors).reverse(),
    [props.sectors, props.recentSectors]
  )

  const suggestedCompanies = useMemo(() => {
    const defaultSuggestions = DEFAULT_SUGGESTED_COMPANIES.filter((option) =>
      props.companies.some((company) => company.name === option.label)
    )

    if (defaultSuggestions.length > 0) {
      return defaultSuggestions
    }

    return props.companies.slice(0, MAX_SUGGESTIONS).map((company) => ({
      label: company.name,
      url: getCompanyUrl(company.name),
    }))
  }, [props.companies])

  const suggestedSectors = useMemo(() => {
    const defaultSuggestions = DEFAULT_SUGGESTED_SECTORS.filter((option) =>
      props.sectors.some((sector) => sector === option.label)
    )

    if (defaultSuggestions.length > 0) {
      return defaultSuggestions
    }

    return props.sectors.slice(0, MAX_SUGGESTIONS).map((sector) => ({
      label: sector,
      url: getSectorUrl(sector),
    }))
  }, [props.sectors])

  const handleOpenCompany = () => {
    if (!selectedCompany) {
      companySelectRef.current?.focus()
      return
    }

    history.push(`${Path.REPORTS_COMPANY}/${selectedCompany.value}`)
  }

  const handleOpenSector = () => {
    if (!selectedSector) {
      sectorSelectRef.current?.focus()
      return
    }

    history.push(`${Path.REPORTS_SECTORAL}/${selectedSector.value}`)
  }

  return props.loading ? null : (
    <div className={classNames('font-work-sans', props.className)}>
      <ESGPlatformHeader />
      <div className="animate-fade-in-300">
        <div className="flex justify-center">
          <div className="mt-8 w-full max-w-screen-xl">
            <div className="border-b border-black px-4 pb-3.5 text-2xl font-bold">
              Empresas
            </div>
            <div className="mt-10 mb-10 grid grid-cols-1 gap-8 sm:grid-cols-2 md:grid-cols-3">
              <div>
                <div className="border-b border-black px-4 pb-3 text-xl font-medium">
                  Favoritas
                </div>
                <PaginatedList maxLength={4} list={favoriteCompanies} />
              </div>
              <div>
                <div className="border-b border-black px-4 pb-3 text-xl font-medium">
                  Vistas Recentemente
                </div>
                <PaginatedList maxLength={4} list={recentCompanies} />
              </div>
              <div>
                <div className="border-b border-black px-4 pb-3 text-xl font-medium">
                  Sugestões
                </div>
                <PaginatedList maxLength={4} list={suggestedCompanies} />
              </div>
            </div>
          </div>
        </div>
        <div className="flex h-26 justify-center bg-jgp-secondary-dark p-4">
          <div className="flex w-full max-w-screen-xl flex-col items-center justify-between md:flex-row">
            <Select
              innerRef={companySelectRef}
              className="w-full text-sm md:flex-grow"
              options={companyOptions}
              value={selectedCompany}
              onChange={(value) => setSelectedCompany(value)}
              placeholder="Selecione a empresa..."
            />
            <button
              className="mt-4 h-8 w-full border border-white bg-transparent text-white focus:outline-none md:mt-0 md:ml-8 md:w-56"
              onClick={handleOpenCompany}
            >
              Abrir
            </button>
          </div>
        </div>
        <div className="flex justify-center">
          <div className="mt-8 w-full max-w-screen-xl">
            <div className="border-b border-black px-4 pb-3.5 text-2xl font-bold">
              Setores
            </div>
            <div className="mt-10 mb-10 grid grid-cols-1 gap-8 sm:grid-cols-2 md:grid-cols-3">
              <div>
                <div className="border-b border-black px-4 pb-3 text-xl font-medium">
                  Favoritos
                </div>
                <PaginatedList maxLength={4} list={favoriteSectors} />
              </div>
              <div>
                <div className="border-b border-black px-4 pb-3 text-xl font-medium">
                  Vistos Recentemente
                </div>
                <PaginatedList maxLength={4} list={recentSectors} />
              </div>
              <div>
                <div className="border-b border-black px-4 pb-3 text-xl font-medium">
                  Sugestões
                </div>
                <PaginatedList maxLength={4} list={suggestedSectors} />
              </div>
            </div>
          </div>
        </div>
        <div className="mb-14 flex h-26 justify-center bg-jgp-secondary-dark p-4">
          <div className="flex w-full max-w-screen-xl flex-col items-center justify-between md:flex-row">
            <Select
              innerRef={sectorSelectRef}
              className="w-full text-sm md:flex-grow"
              options={props.sectors.map((sector) => ({
                label: sector,
                value: slugify(sector),
              }))}
              value={selectedSector}
              onChange={(value) => setSelectedSector(value)}
              placeholder="Selecione o setor..."
            />
            <button
              className="mt-4 h-8 w-full border border-white bg-transparent text-white focus:outline-none md:mt-0 md:ml-8 md:w-56"
              onClick={handleOpenSector}
            >
              Abrir
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Reports

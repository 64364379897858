import classNames from 'classnames'
import React from 'react'
import { Link } from 'react-router-dom'

interface Props {
  active?: boolean
  href: string
  label: string
  onClick?: () => void
}

const PageLink: React.FC<Props> = (props) => (
  <Link
    className={classNames(
      'flex h-full items-center border-b-4 border-transparent px-4 text-sm',
      {
        'border-jgp-success-main text-jgp-success-main': props.active,
      }
    )}
    to={props.href}
    onClick={props.onClick}
  >
    {props.label}
  </Link>
)

export default PageLink
